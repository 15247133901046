async function loadProductCarousel() {
    const container = document.querySelector('[data-product-carousel]');

    if (!container) {
        return;
    }

    const token = document.querySelector('[data-token-name="csrf_token"]').getAttribute('data-token-value');
    let url = `/on/demandware.store/Sites-hush-UK-Site/en_GB/Home-ShowContentSlot?csrf_token=${token}&contentSlotId=1`;
    if (window.location.pathname.indexOf('/s/hush-ROW') === 0 || window.location.pathname.indexOf('/int') === 0) {
        url = `/on/demandware.store/Sites-hush-ROW-Site/default/Home-ShowContentSlot?csrf_token=${token}&contentSlotId=1`;
    }

    const res = await fetch(url);
    const responseText = await res.text();

    if (responseText.indexOf('<script>') === -1) {
        container.innerHTML = responseText;
        return;
    }

    const script = responseText.substring(responseText.indexOf('<script>') + 9, responseText.lastIndexOf('/script>') - 1);
    container.innerHTML = responseText.replace(script, '');

    const scriptEl = document.createElement('script');
    scriptEl.text = script;

    const scriptLocation = container.querySelector('script');
    container.insertBefore(scriptEl, scriptLocation);
    container.removeChild(scriptLocation);
}

addEventListener('DOMContentLoaded', () => {
    if (window.CQuotient) {
        loadProductCarousel();
    }
});